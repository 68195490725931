<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-data-table
          dense
          :headers="headers"
          :items="compModCompLista"
          sortBy="id"
          class="elevation-10"
          :search="search"
          :footer-props="{
            'items-per-page-options': [15],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-icon class="mr-2" color="primary"
                >mdi-view-module-outline</v-icon
              >
              <v-toolbar-title>Componentes do Módulo</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="blue darken-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="metCarregaLista"
                  >
                    mdi-refresh-circle
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="6" class="ml-4" align-self="end">
                  <v-chip-group
                    v-model="listaModCompAtivos"
                    mandatory
                    active-class="primary"
                  >
                    <v-chip @click="listaModCompAtivos = 0"> Ativos </v-chip>
                    <v-chip @click="listaModCompAtivos = 1"> Inativos </v-chip>
                    <v-chip @click="listaModCompAtivos = 2"> Todos </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="4" class="mb-4" align-self="end">
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  :color="metAtivoColor(item.ativo)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaAtivacao(item)"
                >
                  {{ item.ativo ? "mdi-check-bold" : "mdi-close-thick" }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("adminModulos");

export default {
  // mixins: [],
  name: "AdminModulosComponentesLista",
  data: () => ({
    listaModCompAtivos: undefined,
    search: "",
    headers: [
      { text: "Código", value: "id", sortable: false },
      {
        text: "Componente",
        value: "componente",
        align: "left",
        sortable: false,
      },
      {
        text: "Campo",
        value: "campo",
        align: "left",
        sortable: false,
      },

      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["erro", "modulosComponentes", "moduloSelecionado"]),

    ...mapGetters(["getModCompAtivos", "getModCompInativos"]),

    compModCompLista() {
      return this.listaModCompAtivos == 0
        ? this.getModCompAtivos
        : this.listaModCompAtivos == 1
        ? this.getModCompInativos
        : this.modulosComponentes;
    },
  },

  created() {
    register(this.$store);
    this.actResetarModulosComponentes();
    this.listaModCompAtivos = 2;
    this.metCarregaLista();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actListarModulosComponentes",
      "actAtivarModComp",
      "actSyncModComp",
      "actResetarModulosComponentes",
    ]),

    metVoltar() {
      this.$router.back();
    },

    metAtivoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    metParaAtivacao(modComp) {
      this.actAtivarModComp({ modComp });
      this.metCarregaLista();
    },

    login() {
      this.$router.push({ name: "Login" });
    },

    async metCarregaLista() {
      if (this.moduloSelecionado != undefined) {
        await this.actListarModulosComponentes({
          modulo: this.moduloSelecionado,
        });
      }
    },
    async metSyncModComp() {
      this.$root.snackbar.show({
        type: "warning",
        message: "Sincronização Componentes Módulos Iniciada!",
      });
      if (this.moduloSelecionado != undefined) {
        await this.actSyncModComp({
          modulo: this.moduloSelecionado,
        });
        this.$root.snackbar.show({
          type: "warning",
          message: "Sincronização Componentes Módulos Finalizada!",
        });
      } else {
        this.$root.snackbar.show({
          type: "warning",
          message: "Sincronização Componentes Módulos Não Realizada!",
        });
      }
    },
  },
};
</script>
