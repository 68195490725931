<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-view-module-outline</v-icon>
              <v-toolbar-title>Gerenciar Componentes do Módulo</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="moduloLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="moduloLocal.modulo"
                    label="Modulo"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <template>
              <tableComponentes></tableComponentes>
            </template>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("adminModulos");

import tableComponentes from "./AdminModulosCompLista";

export default {
  components: { tableComponentes },
  data: () => ({
    moduloLocal: {},
  }),
  computed: {
    ...mapState({ staModuloSelecionado: "moduloSelecionado" }),
  },
  watch: {
    staModuloSelecionado(moduloNovo) {
      this.metSincronizar(moduloNovo);
    },
  },

  created() {
    this.metSincronizar(this.staModuloSelecionado);
  },

  methods: {
    metSincronizar(novoModulo) {
      this.moduloLocal = Object.assign({}, novoModulo || { id: null });
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
